<template>
    <header class="header header-7">
        <sticky-header>
            <div class="header-middle sticky-header">
                <div class="container">
                    <div class="header-left">
                        <button class="mobile-menu-toggler" @click="openMobileMenu">
                            <span class="sr-only">{{$t("components.ErrorHeaderDefault.toggle_mobile_menu")}}</span>
                            <i class="icon-bars"></i>
                        </button>

                       <nuxt-link to="#" class="logo">
                            <img
                                v-lazy="'https://www.hyatt.com/partner/login/assets/0bbb5c77/images/wo-h-horizontal-2x.png'"
                                class="bg-white"
                                alt="Merchant Logo"
                                id="menu-button"
                            />
                        </nuxt-link>
                    </div>

                    <div class="header-right">
                        <main-menu></main-menu>
                        <!-- <header-search></header-search>
                        <wishlist-menu></wishlist-menu> -->
                        <!-- <cart-menu></cart-menu> -->
                    </div>
                </div>
            </div>
        </sticky-header>
    </header>
</template>

<script>

export default {
    components: {
        
    },
    data(){
        return{
            merchantDetails:''
        }
    },
    computed: {
        isFullwidth: function() {
            return this.$route.path.includes('fullwidth');
        }
    },
    methods: {
        openMobileMenu: function() {
            document.querySelector('body').classList.add('mmenu-active');
        },

        //--------------- Merchant Details Api ----------

        getMerchantDetails: function() {
            console.log("this.$store.state.sessionStorage.Errorheader", this.$store.state.sessionStorage)
            var url = `/api/mzapps/merchant/${this.$store.state.sessionStorage.merchant_id}?timestamp=${new Date().getTime()}&country_code=${this.$route.params.country}`
            this.$axios.$get(url).then(response => {
                this.merchantDetails = response.merchant;
                // document.title = `Catalogue | ${this.merchantDetails.merchant_name}`
                console.log('merchantDetails 404 page',this.merchantDetails.merchant_logourl);
                this.$store.commit('sessionStorage/assignMerchantItem', this.merchantDetails);
                console.log('sessionStorage/assignMerchantItemError header###',this.$store.state.sessionStorage.merchantDetails.merchant_imageurl);
            }).catch(error => {
                console.log("Error in 404 merchant details", error);
            })
        },
    },
    mounted: function() {
        this.getMerchantDetails()
    },
};
</script>
<style>
.header {
    background-color: #2A72CE !important;
}
.sticky-header.fixed {
    background-color: #2A72CE !important;
}
.bg-white {
    width: 40% !important;
}
.mobile-menu-toggler {
    color: #fff !important;
}
@media only screen and (min-width: 291px) and (max-width:320px) {
#menu-button{
margin-left: 1em !important;
}
}
@media only screen and (min-width: 321px) and (max-width:375px) {
#menu-button{
margin-left: 2.5em !important;
}
}
@media only screen and (min-width: 376px) and (max-width:425px) {
#menu-button{
margin-left: 4em !important;
}
}
</style>