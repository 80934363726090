// plugins/i18n.js

import Vue from "vue";
import VueI18n from "vue-i18n";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Tell Vue to use our plugin
Vue.use(VueI18n);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

export default ({ app }) => {
    // Set the i18n instance on app
    // This way we can use it globally in our components through this.$i18n
    app.i18n = new VueI18n({
        // Set the initial locale
        locale: "en",
        lazy: true,
        // Set the fallback locale in case the current locale can't be found
        fallbackLocale: "en",

        // Associate each locale to a content file    
        messages: {
            'en': require("~/locales/content-en.json"),
            'kr': require("~/locales/content-kr.json"),
            'ja': require("~/locales/content-jp.json"),
            'zh_Hans': require("~/locales/content-zh-hans.json"),
            'zh_Hant': require("~/locales/content-zh-hant.json"),
            'id': require("~/locales/content-id.json"),
            'th': require("~/locales/content-th.json"),
        }
    });
};