export default function({ params, $axios, redirect, error: nuxtError }) {
    $axios.defaults.timeout = 7200000;

    $axios.onError(error => {
        // console.log("$axios Error ", error);
        if (error.response.status !== 404 && error.response.status !== 401) {
            console.log("$axios Error ", error.response);
            nuxtError({
                statusCode: error.response.status,
                message: error.message,
            });
            return Promise.reject(error.response);
        } else {
            console.log("$axios Error ", error.response.status);
            // redirect('/' + params.catalogue_id + '/notfound')
            return Promise.reject(error.response);
        }

    })
}
