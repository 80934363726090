<template>
    <nav aria-label="breadcrumb" class="breadcrumb-nav border-0 mb-0">
        <div :class="'d-flex align-items-center ' + (fullWidth ? 'container-fluid' : 'container')">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a href="#" @click="goShop()">{{$t('page.breadcrumb.home')}}</a>
                </li>
                <!-- <li class="breadcrumb-item">
                    <nuxt-link to="/product/default/dark-yellow-lace-cut-out-swing-dress">shop</nuxt-link>
                </li> -->
                <li class="breadcrumb-item active">{{ current }}</li>
            </ol>

            <nav class="product-pager ml-auto" aria-label="Product">
                <nuxt-link
                    :to="prevProduct.slug"
                    class="product-pager-link product-pager-prev"
                    :class="{'prev-only': !nextProduct}"
                    v-if="prevProduct"
                >
                    <i class="icon-angle-left"></i>
                    <span>Prev</span>
                    <div class="product-detail">
                        <figure>
                            <img
                                v-lazy="`${baseUrl}${prevProduct.sm_pictures[0].url}`"
                                alt="product"
                                :width="prevProduct.sm_pictures[0].width"
                                :height="prevProduct.sm_pictures[0].height"
                            />
                        </figure>
                        <h3 class="product-name">{{ prevProduct.name }}</h3>
                    </div>
                </nuxt-link>

                <nuxt-link
                    :to="nextProduct.slug"
                    class="product-pager-link product-pager-next"
                    v-if="nextProduct"
                >
                    <span>Next</span>
                    <i class="icon-angle-right"></i>
                    <div class="product-detail">
                        <figure>
                            <img
                                v-lazy="`${baseUrl}${nextProduct.sm_pictures[0].url}`"
                                alt="product"
                                :width="nextProduct.sm_pictures[0].width"
                                :height="nextProduct.sm_pictures[0].height"
                            />
                        </figure>
                        <h3 class="product-name">{{ nextProduct.name }}</h3>
                    </div>
                </nuxt-link>
            </nav>
        </div>
    </nav>
</template>
<script>
import { baseUrl } from '~/repositories/repository';

export default {
    props: {
        prevProduct: null,
        nextProduct: null,
        current: String,
        fullWidth: {
            type: Boolean,
            default: function() {
                return false;
            }
        },
        catalogueId: {
            type: String
        }
    },
    data: function() {
        return {
            baseUrl: baseUrl
        };
    },
    methods: {
        goShop:function(){
            var catalogueDetails = this.$store.state.sessionStorage.catalogueDetails;
            var customer_id = this.$store.state.sessionStorage.customer_id
            if (catalogueDetails.catalogue_theme.theme_name_id !== 'PRODUCT_THEME') {
                var url =`/${this.catalogueId}/shop/category/boxed?customer_id=${customer_id}`
            }else{
                var url =`/${this.catalogueId}/shop/sidebar/list?customer_id=${customer_id}`
            }
            window.location.replace(url)
        }
    }
};
</script>
<style scoped>
.breadcrumb-item + .breadcrumb-item:before{
    font-size: 1.5rem !important;
        margin-top: 0rem !important;
}
</style>