import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _40d8b656 = () => interopDefault(import('../pages/heartbeat.vue' /* webpackChunkName: "pages/heartbeat" */))
const _e59cb074 = () => interopDefault(import('../pages/pages/404.vue' /* webpackChunkName: "pages/pages/404" */))
const _19156baa = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6f752611 = () => interopDefault(import('../pages/_country/index.vue' /* webpackChunkName: "pages/_country/index" */))
const _37ca095b = () => interopDefault(import('../pages/_country/balance.vue' /* webpackChunkName: "pages/_country/balance" */))
const _4312bd74 = () => interopDefault(import('../pages/_country/forgot.vue' /* webpackChunkName: "pages/_country/forgot" */))
const _391cc928 = () => interopDefault(import('../pages/_country/login.vue' /* webpackChunkName: "pages/_country/login" */))
const _6a0faa17 = () => interopDefault(import('../pages/_country/overstatus.vue' /* webpackChunkName: "pages/_country/overstatus" */))
const _2e46666c = () => interopDefault(import('../pages/_country/overwrite.vue' /* webpackChunkName: "pages/_country/overwrite" */))
const _b0f8dca0 = () => interopDefault(import('../pages/_country/pinredeem.vue' /* webpackChunkName: "pages/_country/pinredeem" */))
const _4f5b32b4 = () => interopDefault(import('../pages/_country/recovery.vue' /* webpackChunkName: "pages/_country/recovery" */))
const _12ca829f = () => interopDefault(import('../pages/_country/redeemstatus.vue' /* webpackChunkName: "pages/_country/redeemstatus" */))
const _216af16e = () => interopDefault(import('../pages/_country/refund.vue' /* webpackChunkName: "pages/_country/refund" */))
const _d21299ae = () => interopDefault(import('../pages/_country/resetpassword.vue' /* webpackChunkName: "pages/_country/resetpassword" */))
const _608c64b8 = () => interopDefault(import('../pages/_country/retrivestatus.vue' /* webpackChunkName: "pages/_country/retrivestatus" */))
const _3da6f808 = () => interopDefault(import('../pages/_country/transfer.vue' /* webpackChunkName: "pages/_country/transfer" */))
const _a7492024 = () => interopDefault(import('../pages/_country/transferstatus.vue' /* webpackChunkName: "pages/_country/transferstatus" */))
const _b10ce210 = () => interopDefault(import('../pages/_country/updatecustomer.vue' /* webpackChunkName: "pages/_country/updatecustomer" */))
const _3c3ad07c = () => interopDefault(import('../pages/_country/_id/index.vue' /* webpackChunkName: "pages/_country/_id/index" */))
const _6398c0da = () => interopDefault(import('../pages/_country/_id/shop/sidebar/_type.vue' /* webpackChunkName: "pages/_country/_id/shop/sidebar/_type" */))
const _561d93f4 = () => interopDefault(import('../pages/_country/_id/_resetpassword/index.vue' /* webpackChunkName: "pages/_country/_id/_resetpassword/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/heartbeat",
    component: _40d8b656,
    name: "heartbeat"
  }, {
    path: "/pages/404",
    component: _e59cb074,
    name: "pages-404"
  }, {
    path: "/",
    component: _19156baa,
    name: "index"
  }, {
    path: "/:country",
    component: _6f752611,
    name: "country"
  }, {
    path: "/:country/balance",
    component: _37ca095b,
    name: "country-balance"
  }, {
    path: "/:country/forgot",
    component: _4312bd74,
    name: "country-forgot"
  }, {
    path: "/:country/login",
    component: _391cc928,
    name: "country-login"
  }, {
    path: "/:country/overstatus",
    component: _6a0faa17,
    name: "country-overstatus"
  }, {
    path: "/:country/overwrite",
    component: _2e46666c,
    name: "country-overwrite"
  }, {
    path: "/:country/pinredeem",
    component: _b0f8dca0,
    name: "country-pinredeem"
  }, {
    path: "/:country/recovery",
    component: _4f5b32b4,
    name: "country-recovery"
  }, {
    path: "/:country/redeemstatus",
    component: _12ca829f,
    name: "country-redeemstatus"
  }, {
    path: "/:country/refund",
    component: _216af16e,
    name: "country-refund"
  }, {
    path: "/:country/resetpassword",
    component: _d21299ae,
    name: "country-resetpassword"
  }, {
    path: "/:country/retrivestatus",
    component: _608c64b8,
    name: "country-retrivestatus"
  }, {
    path: "/:country/transfer",
    component: _3da6f808,
    name: "country-transfer"
  }, {
    path: "/:country/transferstatus",
    component: _a7492024,
    name: "country-transferstatus"
  }, {
    path: "/:country/updatecustomer",
    component: _b10ce210,
    name: "country-updatecustomer"
  }, {
    path: "/:country/:id",
    component: _3c3ad07c,
    name: "country-id"
  }, {
    path: "/:country/:id/shop/sidebar/:type?",
    component: _6398c0da,
    name: "country-id-shop-sidebar-type"
  }, {
    path: "/:country/:id/:resetpassword",
    component: _561d93f4,
    name: "country-id-resetpassword"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
