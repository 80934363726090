<template>
    <div class="dropdown cart-dropdown">
        <a href="#" @click="ViewCart()" class="dropdown-toggle">
            <i class="icon-shopping-cart"></i>
            <span class="cart-count">{{ qtyTotal }}</span>
            <span class="cart-txt"><span v-if="paymentDetails">{{paymentDetails.currency}}</span> {{ priceTotal.toFixed(2) }}</span>
        </a>

        <div class="dropdown-menu dropdown-menu-right" id="dropdown-menu" v-if="cartList.length > 0" key="hasCart">
            <div class="dropdown-cart-products">
                <div class="product" v-for="(product, index) in cartList" :key="index">
                    <div class="product-cart-details">
                        <h4 class="product-title">
                            <div>{{ product.product_name }}</div>
                        </h4>

                        <span class="cart-product-info">
                            <span class="cart-product-qty">{{ product.qty }}</span>
                            x <span v-if="paymentDetails">{{paymentDetails.currency}}</span> {{ product.selling_price ? product.selling_price.toFixed(2): product.selling_price.toFixed(2) }}
                        </span>
                    </div>

                    <figure class="product-image-container" >
                        <nuxt-link :to="'/'+catalogueId+'/product/default/'+product.catalogue_product_id" class="product-image">
                            <img
                                v-lazy="product.product_images.length > 0 ? product.product_images[0].product_image : merchantImage"
                                alt="product"
                            />
                        </nuxt-link>
                    </figure>
                    <a
                    v-if="catalogueDetails.product_limit_control !== 'S'"
                        href="#"
                        class="btn-remove"
                        title="Remove Product"
                        @click.prevent="removeFromCart({product: product})"
                    >
                        <i class="icon-close"></i>
                    </a>
                    <a
                        href="#"
                        class="btn-remove"
                        title="Remove Product"
                        @click.prevent="removeCartItem({product: product})"
                    >
                        <i class="icon-close"></i>
                    </a>
                </div>
            </div>

            <div class="dropdown-cart-total">
                <span>{{$t("components.CartMenu.total")}}</span>

                <span class="cart-total-price"><span v-if="paymentDetails">{{paymentDetails.currency}}</span> {{ priceTotal.toFixed(2) }}</span>
            </div>

            <div class="dropdown-cart-action">
                <a href="#" @click="ViewCart()" class="btn btn-primary">{{$t("components.CartMenu.view_cart")}}</a>
                <a  href="#" @click="ViewCheckOut()" class="btn btn-outline-primary-2">
                    <span>{{$t("components.CartMenu.checkout")}}</span>
                    <i class="icon-long-arrow-right"></i>
                </a>
            </div>
        </div>
        <div class="dropdown-menu dropdown-menu-right" v-else key="noCart">
            <p class="text-center">{{$t("components.CartMenu.description_title")}}</p>
        </div>
    </div>
</template>
<script>
// import { mounted } from 'vueisotope';
import { mapGetters, mapActions } from 'vuex';
import { baseUrl } from '~/repositories/repository';

export default {
    data: function() {
        return {
            baseUrl: baseUrl,
            catalogueId:'',
            paymentDetails:'',
            merchantDetails:'',
            merchantImage:'',
            catalogueDetails:''

        };
    },
     mounted: function() {
        console.log('this.$store.state.sessionStorage.catalogueDetails',this.$store.state.sessionStorage.catalogueDetails);
        this.catalogueId = this.$route.params.catalogue_id;
        this.catalogueDetails = this.$store.state.sessionStorage.catalogueDetails;
        this.merchantDetails = this.$store.state.sessionStorage.merchantDetails;
        this.merchantImage = this.merchantDetails.merchant_logourl;
        // this.getActivePayment()
    },
    computed: {
        ...mapGetters('cart', ['cartList', 'priceTotal', 'qtyTotal'])
    },
    methods: {
        
        ...mapActions('cart', ['removeFromCart']),
                            // <----------- Active Payment Api --------------->

        getActivePayment: function() {
        var url = `/api/mzapps/activepayment?timestamp=${new Date().getTime()}&country_code=${this.$route.params.country}`
        this.$axios.$get(url).then(response => {
          response.results[0].payment.currency = (response.results[0].payment.currency === 'JPY') ? '¥' : response.results[0].payment.currency;
            this.paymentDetails = response.results[0].payment;
            // console.log('this.paymentDetails',this.paymentDetails);
        }).catch(error => {
            // alert('hek')
        })
    },   
    removeCartItem:function(product) {
            console.log('Product ',product);
            //product.added_to_cart = true;
            this.$store.commit('sessionStorage/RemoveFromCart', product);
            console.log('After sessionStorage deletion ',product);
            console.log("this pointer :",this)
            this.removeFromCart( product )  
            window.location.reload(true)          
        },
        ViewCart: function() {
            console.log('helllo');
            var lang =  this.$route.query.lang;
            var login_status =  this.$route.query.login_status;
            var customer_id = this.$route.query.customer_id;
             var url = `/${this.catalogueId}/shop/cart?lang=${lang}&login_status=${login_status}&customer_id=${customer_id}`
            window.location.replace(url)
        },
        ViewCheckOut: function() {
             var url = `/${this.catalogueId}/shop/checkout`
            window.location.replace(url)
        }
    },
};
</script>
<style>

@media only screen and (max-device-width: 991px)  {
    #dropdown-menu{
        display: none !important;
    }
}
</style>