<template>
    <header class="header header-7">
        <sticky-header>
            <div class="header-middle sticky-header">
                <div class="container">
                    <div class="header-left">
                        <button class="mobile-menu-toggler"  @click="openMobileMenu">
                            <span class="sr-only">{{$t("components.ErrorHeaderDefault.toggle_mobile_menu")}}</span>
                            <i class="icon-bars"></i>
                        </button>

                        <nuxt-link to="#" class="logo">
                            <img
                                v-lazy="'https://www.hyatt.com/partner/login/assets/0bbb5c77/images/wo-h-horizontal-2x.png'"
                                class="bg-white"
                                alt="Molla Logo"
                                id="menu-button"
                                
                            />
                        </nuxt-link>
                    </div>

                    <div class="header-right">
                        <filter-menu></filter-menu>
                        <main-menu></main-menu>
                        <!-- <header-search></header-search> -->
                        <!-- <wishlist-menu></wishlist-menu> -->
                        <user-menu></user-menu>
                        <!-- <cart-menu></cart-menu> -->
                    </div>
                </div>
          
            </div>
        </sticky-header>
    </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FilterMenu from '~/components/partial/headers/shared/FilterMenu';
import CartMenu from '~/components/partial/headers/shared/CartMenu';
import UserMenu from '~/components/partial/headers/shared/UserMenu';
import MainMenu from '~/components/partial/headers/shared/MainMenu';
import StickyHeader from '~/components/elements/StickyHeader';

export default {
    components: {
        FilterMenu,
        CartMenu,
        MainMenu,
        StickyHeader,
        UserMenu
    },
    data: function() {
        return {
            MerchantDetails:'',
            catalogueId:'',
            paymentDetails:'',
        }
    },
    computed: {
        ...mapGetters('cart', ['cartList', 'priceTotal', 'qtyTotal']),
        isFullwidth: function() {
            return this.$route.path.includes('fullwidth');
        }
        
    },
    methods: {
        openMobileMenu: function() {
            document.querySelector('body').classList.add('mmenu-active');
        },

         //--------------- Merchant Details Api ----------

        getMerchantDetails: function() {
            var url = `/api/mzapps/merchant?timestamp=${new Date().getTime()}&country_code=${this.$route.params.country}`
            this.$axios.$get(url).then(response => {
                this.MerchantDetails = response.merchant;
                // document.title = `Catalogue | ${this.MerchantDetails.merchant_name}`
                // console.log('merchantDetails',this.merchantDetails.merchant_logourl);
                this.$store.commit('sessionStorage/assignMerchantItem', this.MerchantDetails);
                console.log('merchantDetails###',this.$store.state.sessionStorage.MerchantDetails.merchant_imageurl);
            }).catch(error => {

            })
        },

        ViewCheckOut: function() {
             var url = `/${this.catalogueId}/shop/checkout`
            window.location.replace(url)
        },

         // <----------- Active Payment Api --------------->
        getActivePayment: function() {
        var url = `/api/mzapps/activepayment/currency?timestamp=${new Date().getTime()}&country_code=${this.$route.params.country}`
        this.$axios.$get(url).then(response => {
            response.currency = (response.currency === 'JPY') ? '¥' : response.currency;
            this.paymentDetails = response;
            console.log('this.paymentDetails',this.paymentDetails);
        }).catch(error => {
            // alert('hek')
        })
    },
        
    },
     mounted: function() {
        this.getMerchantDetails()
        // this.getActivePayment()
        this.catalogueId = this.$route.params.catalogue_id;
        
    },
};
</script>
<style >
@media only screen and (max-device-width: 991px) {
 .logo img {
    max-width: 50% !important;
 }
 .sticky-header.fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1040;
    -webkit-animation-name: fixedHeader;
    animation-name: fixedHeader;
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    background-color: #fff;
    box-shadow: 0 3px 6px rgb(51 51 51 / 5%);
}
}
.header {
    background-color: #2A72CE !important;
}
.sticky-header.fixed {
    background-color: #2A72CE !important;
}
.bg-white {
    width: 40% !important;
}
.mobile-menu-toggler {
    color: #fff !important;
}
@media only screen and (min-width: 291px) and (max-width:320px) {
#menu-button{
margin-left: 1em !important;
}
}
@media only screen and (min-width: 321px) and (max-width:375px) {
#menu-button{
margin-left: 2.5em !important;
}
}
@media only screen and (min-width: 376px) and (max-width:425px) {
#menu-button{
margin-left: 4em !important;
}
}
</style>