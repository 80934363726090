// Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const UPDATE_CART = 'UPDATE_CART';

export const state = () => ({
    data: []
});

export const getters = {
    cartList: state => {
        return state.data
    },
    priceTotal: state => {
        // console.log('state', state);
        return state.data.reduce((acc, cur) => {
            // console.log('acc', acc);
            return acc + cur.sum
        }, 0);
    },
    qtyTotal: state => {
        return state.data.reduce((acc, cur) => {
            return acc + parseInt(cur.qty, 10);
        }, 0);
    },
    isInCart: state => (product) => {
        return state.data.find(item => item.catalogue_product_id == product.catalogue_product_id) ? true : false;
    },

    canAddToCart: state => (product, qty = 1) => {
        // console.log('product', product.catalogue_product_id);
        // console.log('state', state);
        var sate_data = state.data;
        // console.log('sate_data', sate_data);
        var find = sate_data.find(item => item.catalogue_product_id === product.catalogue_product_id);
        console.log('findytdy', find);
        if (find) {

            if (product.available_coupon == 0 || (product.available_coupon < (find.qty + qty))) return false;
            else return true;
        } else {
            if (product.available_coupon == 0 || (product.available_coupon < qty)) return false;
            else return true;
        }
    }
}

export const actions = {
    clearCart: function({ commit, getters }, payload) {
        commit(CLEAR_CART, payload);
    },

    addToCart: function({ commit, getters }, payload) {
        console.log('payload', payload);
        if (payload.qty !== undefined) {
            var multiQty = payload.qty;
        } else {
            var multiQty = 1;
        }

        payload.product.qty = multiQty;
        if (!getters.canAddToCart(payload.product, payload.product.qty)) {
            this._vm.$vToastify.removeToast();
            this._vm.$vToastify.setSettings({
                withBackdrop: false,
                position: "top-right",
                errorDuration: 2000
            });
            this._vm.$vToastify.error("Sorry, you can't add that amount to the cart.");
            return;
        }

        commit(ADD_TO_CART, payload);
        this._vm.$vToastify.setSettings({
            withBackdrop: false,
            position: "top-right",
            successDuration: 1500,
        });
        this._vm.$vToastify.success("Product added to cart");
    },
    removeFromCart: function({ commit }, payload) {
        commit(REMOVE_FROM_CART, payload);
        this._vm.$vToastify.setSettings({
            withBackdrop: false,
            position: "top-right",
            successDuration: 1500,
        });
        this._vm.$vToastify.success("Product removed from cart");
    },
    updateCart: function({ commit }, payload) {
        commit(UPDATE_CART, payload);
        this._vm.$vToastify.setSettings({
            withBackdrop: false,
            position: "top-right",
            successDuration: 1500,
        });
        this._vm.$vToastify.success("Cart successfully updated");
    }
}

export const mutations = {
    [ADD_TO_CART](state, payload) {
        // console.log('payload', payload);
        // console.log('state', state);
        var findIndex = state.data.findIndex(item => item.catalogue_product_id == payload.product.catalogue_product_id);
        let qty = payload.product.qty ? payload.product.qty : 1;
        if (findIndex !== -1 && payload.product.length > 0) {
            findIndex = state.data.findIndex(item => item.product_name == payload.product.product_name);
        }
        console.log('findIndex', findIndex);
        if (findIndex !== -1) {
            // console.log('');
            state.data = state.data.reduce((acc, product, index) => {

                // console.log('product', product);
                // console.log('index', index);


                if (findIndex == index) {
                    acc.push({
                        ...product,
                        qty: product.qty + qty,
                        sum: (payload.product.selling_price ? payload.product.selling_price : payload.product.selling_price) * (product.qty + qty)
                    });
                    // console.log('acc', acc);
                } else {
                    // console.log('else-product', product);
                    acc.push(product);
                    // console.log('else-acc', acc);
                }

                return acc;
            }, []);
        } else {
            state.data = [
                ...state.data,
                {
                    ...payload.product,
                    qty: qty,
                    price: payload.product.selling_price ? payload.product.selling_price : payload.product.selling_price,
                    sum: qty * (payload.product.selling_price ? payload.product.selling_price : payload.product.selling_price)
                }
            ];
        }
    },

    [REMOVE_FROM_CART](state, payload) {
        console.log('');
        state.data = state.data.filter(item => {
            if (item.catalogue_product_id !== payload.product.catalogue_product_id) return true;
            if (item.product_name !== payload.product.product_name) return true;
            return false;
        });
    },

    [UPDATE_CART](state, payload) {
        state.data = payload.cartItems.reduce((acc, cur) => {
            return [
                ...acc,
                {
                    ...cur,
                    sum: (cur.selling_price ? cur.selling_price : cur.selling_price) * cur.qty
                }
            ]
        }, []);
    },

    [CLEAR_CART](state) {
        state.data = [];
    }
}