import createPersistedState from 'vuex-persistedstate';

export default ({ store, params, query }) => {
    console.log('localStorage', localStorage);
    let storage = localStorage.getItem('molla-vue');
    console.log('storage', JSON.parse(storage));
    if (storage !== null) {
        var cart = JSON.parse(storage).cart;
        var carts = {
                'cart': cart
            }
            // console.log('carts', carts);
        var cart_data = JSON.stringify(carts)
            // console.log('cart_data', cart_data);
        localStorage.setItem('molla-vue', cart_data);
    }
    // console.log('localStorage', localStorage);
    // console.log(store.state.sessionStorage.catalogueid, '===', params.catalogue_id);
    // console.log('query.clear_cart',query.clear_cart ,"typeof(query.clear_cart)",typeof(query.clear_cart));
    if (store.state.sessionStorage.catalogueid !== params.catalogue_id || query.clear_cart === 'true') {
        localStorage.clear();
        store.commit('sessionStorage/assignCatalogueId', params.catalogue_id)
    }


    // if (storage && JSON.parse(storage).demo.current != 11) {
    //     localStorage.clear();
    // }

    window.onNuxtReady(() => {
        createPersistedState({
            key: 'molla-vue',
            paths: []
        })(store)
    })
}