const middleware = {}

middleware['checkparams'] = require('../middleware/checkparams.js')
middleware['checkparams'] = middleware['checkparams'].default || middleware['checkparams']

middleware['checkparamsurl'] = require('../middleware/checkparamsurl.js')
middleware['checkparamsurl'] = middleware['checkparamsurl'].default || middleware['checkparamsurl']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

export default middleware
