<template>
    <div class="product-details-tab">
        <div class="row">
                    <div class="col-md-12">
                        <div class="accordion accordion-rounded" id="accordion-5">
                            <div class="card card-box card-sm bg-light">
                                <div class="card-header" id="heading5-1">
                                    <h2 class="card-title">
                                        <a
                                            class="toggle-button"
                                            :class="{expanded: toggleState[4][0], collapsed: !toggleState[4][0]}"
                                            @click.prevent="changeToggle(4, 0)"
                                            href="#"
                                        >{{$t('components.infoTab.description')}}</a>
                                    </h2>
                                </div>

                                <vue-slide-toggle :open="toggleState[4][0]">
                                    <div
                                        class="card-body"
                                    >{{product.coupon.coupon_desc}}</div>
                                </vue-slide-toggle>
                            </div>

                            <div class="card card-box card-sm bg-light">
                                <div class="card-header" id="heading5-2">
                                    <h2 class="card-title">
                                        <a
                                            class="toggle-button"
                                            :class="{expanded: toggleState[4][1], collapsed: !toggleState[4][1]}"
                                            @click.prevent="changeToggle(4, 1)"
                                            href="#"
                                        >{{$t('components.infoTab.terms&condition')}}</a>
                                    </h2>
                                </div>

                                <vue-slide-toggle :open="toggleState[4][1]">
                                    <div
                                        class="card-body"
                                    v-html="product.coupon.coupon_tc"
                                    ></div>
                                </vue-slide-toggle>
                            </div>

                            <div class="card card-box card-sm bg-light">
                                <div class="card-header" id="heading5-3">
                                    <h2 class="card-title">
                                        <a
                                            class="toggle-button"
                                            :class="{expanded: toggleState[4][2], collapsed: !toggleState[4][2]}"
                                            @click.prevent="changeToggle(4, 2)"
                                            href="#"
                                        >{{$t('components.infoTab.RedemptionDetails')}}</a>
                                    </h2>
                                </div>

                                <vue-slide-toggle :open="toggleState[4][2]">
                                    <div class="card-body" v-if="product.coupon.display_redemption === 'A'&& product.outlets.length > 0">
                                        <ul v-for="(outlet, i) of product.outlets" :key="i">
                                            <li v-if="outlet.outlet.outlet_name !== null && outlet.outlet.outlet_name !== ''"><b><i class="la la-bookmark  la-lg" style="color:#000;"></i>&nbsp;{{outlet.outlet.outlet_name}}</b></li>
                                            <!-- <li v-if="outlet.outlet.outlet_desc !== null && outlet.outlet.outlet_desc !== ''">{{outlet.outlet.outlet_desc}}</li> -->
                                            <li v-if="outlet.outlet.outlet_address !== null && outlet.outlet.outlet_address !== ''"><i class="la la-map-marker la-lg" style="color:#000;"></i>&nbsp;{{outlet.outlet.outlet_address}}</li>
                                            <li v-if="outlet.outlet.outlet_email_id !== null && outlet.outlet.outlet_email_id !== ''"><i class="la la-envelope la-lg" style="color:#000;"></i>&nbsp;{{outlet.outlet.outlet_email_id}}</li>
                                            <li v-if="outlet.outlet.outlet_contact !== null && outlet.outlet.outlet_contact !== ''"><i class="la la-phone la-lg" style="color:#000;"></i>&nbsp;{{outlet.outlet.outlet_contact}}</li>
                                        </ul>
                                    </div>
                                    <div class="card-body" v-else v-html="product.coupon.redemption_message"></div>
                                </vue-slide-toggle>
                            </div>
                        </div>
                    </div>

                    
                </div>
    </div>
</template>
<script>
import { VueSlideToggle } from 'vue-slide-toggle';
export default {
    components: {
        VueSlideToggle
    },
    props: {
        product: {
            type: Object
        }
    },
    data: function() {
        return {
            toggleState: [
                [true, false, false],
                [true, false, false],
                [true, false, false],
                [true, false, false],
                [true, false, false],
                [true, false, false]
            ],
        };
    },
     methods: {
        changeToggle: function(index1, index2) {
            this.toggleState = this.toggleState.reduce((acc1, cur1, id1) => {
                if (id1 == index1) {
                    let newStates = cur1.reduce((acc2, cur2, id2) => {
                        if (id2 == index2) return [...acc2, !cur2];
                        else return [...acc2, false];
                    }, []);

                    return [...acc1, newStates];
                } else {
                    return [...acc1, cur1];
                }
            }, []);
        }
    },
    mounted: function() {
    }
};
</script>
<style>
#accordion-5{
    margin-bottom:-4rem !important;
}
</style>