<template>
<nav class="main-nav">
    <ul class="menu sf-arrows">

        <li>
            <a href="" id="lang" class="sf-with-ul">{{$t("components.main_menu.language")}}</a>

            <ul>
                <li v-for="(lang, index) of selectedLang" :key="index">
                    <a href="#" @click.prevent="lang_change(lang.code)">{{lang.name}}<br><span v-if="lang.code === 'ja'">(日本語)</span><span v-if="lang.code === 'th'">(ภาษาไทย)</span><span v-if="lang.code === 'zh_Hans'">(簡體中文)</span><span v-if="lang.code === 'zh_Hant'">(繁體中文)</span><span v-if="lang.code === 'kr'">(한국어)</span></a>
                </li>

            </ul>
        </li>
        <!-- <li  v-if="CatalogueType === 'PR' && user_display !== 'N'" >
                <nuxt-link to="#" id="userMenu" class="sf-with-ul "><i class="icon-user"></i></nuxt-link>

                <ul>
                    <li >
                        <a href="#" class="usermenu" @click="ViewWallet()">{{$t('components.UserMenu.coupon_wallet')}}</a>
                    </li>
                    <li  v-if="customerS_details.wallet_url !== null && customerS_details.wallet_url !== ''">
                        <a href="#" class="usermenu"  @click="logout()">{{$t('components.UserMenu.sign_out')}}</a>
                    </li>
                </ul>
            </li> -->
        <!-- <li :class="{active: current=='blog'}">
                <nuxt-link to="#">
                    <i class="icon-user" style="font-size:25px"></i>
                </nuxt-link>

                <ul>
                    <li>
                        <nuxt-link to="#">Coupon Wallet</nuxt-link>
                    </li>
                    <li>
                        <nuxt-link to="#">Sign Out</nuxt-link>
                    </li>

                </ul>
            </li> -->
    </ul>
</nav>
</template>

<script>
export default {
    data: function () {
        return {
            MerchantSettings: '',
            selectedLang: '',
            catalogueId: '',
            paymentDetails: '',
            catalogueDetails: '',
            CatalogueType: 'PU',
            customerS_details: '',
            customer_id: null,
            user_display: 'N'
        }
    },
    computed: {},
    mounted() {
        // console.log('this.$store.state.sessionStorage.catalogueDetails',this.$store.state.sessionStorage.catalogueDetails);
        // this.catalogueDetails = this.$store.state.sessionStorage.catalogueDetails;
        // this.catalogueId = this.$store.state.sessionStorage.catalogueDetails.catalogue_code;
        // this.CatalogueType = this.$store.state.sessionStorage.catalogueDetails.catalogue_mode;
        // this.user_display = this.$store.state.sessionStorage.user_display
        // this.customer_id = this.$store.state.sessionStorage.customer_id;
        // console.log('CatalogueType',this.CatalogueType);
        // if (this.customer_id !== undefined && this.customer_id !== null) {
        //   this.GetCustomer();
        // }
        this.merchantSettings();
    },
    methods: {
        // <================================ Merchant Settings Api function ====================================>

        merchantSettings: function () {
            var url = `/api/mzapps/settings?country_code=${this.$route.params.country}&timestamp=${new Date().getTime()}`
            this.$axios.$get(url).then(response => {
                console.log("merchantSettings ", response)
                this.MerchantSettings = response;
                if (this.MerchantSettings) {
                    if (this.MerchantSettings.selected_language) {
                        var selected_language = this.MerchantSettings.selected_language;
                        var selectLanguage = [];
                        if (selected_language.kr) {
                            var language_object_kr = {};
                        }
                        if (selected_language.en) {
                            var language_object_en = {};
                        }
                        if (selected_language.zh_Hant) {
                            var language_object_zh_Hant = {};
                        }
                        if (selected_language.zh_Hans) {
                            var language_object_zh_Hans = {};
                        }
                        if (selected_language.id) {
                            var language_object_id = {};
                        }
                        if (selected_language.th) {
                            var language_object_th = {};
                        }
                        if (selected_language.ja) {
                            var language_object_ja = {};
                        }
                        if (selected_language.kr) {
                            language_object_kr.name = "Korean";
                            language_object_kr.code = "kr";
                            language_object_kr.code_align = "F";
                            selectLanguage.push(language_object_kr);
                        }

                        if (selected_language.en) {
                            language_object_en.name = "English";
                            language_object_en.code = "en";
                            language_object_en.code_align = "A";
                            selectLanguage.push(language_object_en);
                        }

                        if (selected_language.zh_Hant) {
                            language_object_zh_Hant.name = "Chinese Traditional";
                            language_object_zh_Hant.code = "zh_Hant";
                            language_object_zh_Hant.code_align = "C";
                            selectLanguage.push(language_object_zh_Hant);
                        }

                        if (selected_language.zh_Hans) {
                            language_object_zh_Hans.name = "Chinese Simplified";
                            language_object_zh_Hans.code = "zh_Hans";
                            language_object_zh_Hans.code_align = "D";
                            selectLanguage.push(language_object_zh_Hans);
                        }

                        if (selected_language.id) {
                            language_object_id.name = "Bahasa Indonesia";
                            language_object_id.code = "id";
                            language_object_id.code_align = "B";
                            selectLanguage.push(language_object_id);
                        }

                        if (selected_language.th) {
                            language_object_th.name = "Thai";
                            language_object_th.code = "th";
                            language_object_th.code_align = "G";
                            selectLanguage.push(language_object_th);
                        }

                        if (selected_language.ja) {
                            language_object_ja.name = "Japanese";
                            language_object_ja.code = "ja";
                            language_object_ja.code_align = "E";
                            selectLanguage.push(language_object_ja);
                        }

                        this.selectedLang = selectLanguage;
                        this.selectedLang.sort((a, b) => a.code_align.localeCompare(b.code_align));
                    }
                }

                console.log('this.selectedLang', this.selectedLang);
            }).catch(error => {

            })
        },

        // <--------------------- language change function --------------------->

        lang_change: function(lang_code){
            this.$store.commit('sessionStorage/assignSelectLang', lang_code);
            window.location.reload()
        // MultiLang(lang_code)
        },

        // <----------- Get Coustomer Details Api Function --------------->
        async GetCustomer() {
            var customerId = this.customer_id;
            console.log('MainMenu- customer_id', customerId);
            var encrypt = this.$route.query.encrypted ? this.$route.query.encrypted : 'N'
            await this.$axios.$get(`/api/mzapps/customer/${customerId}?country_code=${this.$route.params.country}&encrypted=${encrypt}`).then(response => {
                console.log('customer respponse', response);
                this.customerS_details = response;
                this.$store.commit('sessionStorage/assignuserDetails', response);
            }).catch(error => {
                console.log("customer Error ", error);
            })

        },

        //<---------------- logout Function ------------------>

        logout: function () {
            console.log('logout');
            var userDisplayFlag = 'N'
            this.$store.commit('sessionStorage/assignuserDisplay', userDisplayFlag)
            window.location.replace('/login?catalogue_code=' + this.catalogueId)
        },

        //<---------------- View Wallet Function ------------------>

        ViewWallet: function () {
            var wallet_url = this.customerS_details.wallet_url;
            // window.open(wallet_url)
            window.open(wallet_url, "_blank")
            // window.location.replace(wallet_url)
        }
    }
};
</script>

<style scoped>
.header.header-7 .main-nav {
    margin-right: 0rem !important;
}

#userMenu {
    font-size: 32px !important;
    color: #666 !important;
}

.menu.sf-arrows #userMenu::after {
    display: none !important;
}

.header.header-7 .menu>li>a::before {
    background: none !important;
}

#userMenu:hover {
    color: #cc9966 !important;
}
#lang{
    color: #fff !important;
}
</style>