<template>
    <div class="product-details" v-if="product">
        <p class="" style="color:#777777;font-size:20px;font-weight:400;margin-bottom:0em;">{{ product.merchant.merchant_name }}</p>
        <pre class="product-titlec pre-name1">{{ product.coupon.coupon_name }}</pre>

        <div class="ratings-container">
        </div>
        

        <template >
            <template>
                <div
                    class="product-price"
                    v-if="product.merchant.currency && product.coupon.face_value > 0"
                ><span >{{product.merchant.currency}}</span>&nbsp;{{product.coupon.face_value.toFixed(2)}}</div>
            </template>
        </template>
        <p class="product-content"><strong>{{ $t('components.DetailOne.Availability') }}</strong> <span>{{ product.serial.serial_count }}</span></p>
        <p class="product-content" v-if="product.coupon.gtin !== null && product.coupon.gtin !== ''" style="margin-top:-1em"><strong>{{ $t('components.DetailOne.sku') }}</strong> <span>{{product.coupon.gtin}}</span></p>
        <div class="product-content">
            <!-- <pre class="pre-desc1">{{ product.coupon.coupon_desc }}</pre> -->
        </div>


       
       
    </div>
</template>
<script>

import { VueSlideToggle } from 'vue-slide-toggle';


export default {
    components: {
        VueSlideToggle,
       
    },
    props: {
        product: {
            type: Object
        }
    },    
    data: function() {
        return {
            
        };
    },
    computed: {
       
    },
     mounted: function() {
         
    },
    created: function() {
      
    },
    methods: {
         // <<<<<<<<<<<<<<<<<<<<<<<<< go back button >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

       
        

       
       

        

                    
    
    }
};
</script>
<style >
.pre-name1{
    width: 100% !important;
    word-wrap: break-word !important;
    font-size: 24px !important;
    white-space: pre-line !important;
    font-family: inherit !important;
    overflow: hidden !important;
    font-weight: 400 !important;
    color: #666;
}
.pre-desc1{
    width: 100% !important;
    word-wrap: break-word !important;
    font-size: 14px !important;
    white-space: pre-line !important;
    font-family: inherit !important;
    overflow: hidden !important;
    font-weight: 300 !important;
    color: #777777;
    line-height: 1 !important;
}
@media only screen and (max-width: 580px){

    #go-back {
    display: none !important;
    }
    #share-btn {
    display: none !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 990px) {

    #go-back {
    margin-top: 1em !important;
    }
}
@media only screen and (min-width: 580px){

    #go-back-mobile {
    display: none !important;
    }
    #share-btn-mobile {
    display: none !important;
    }
    
}

.pr-4 {
    padding-right: 0rem!important;
}
</style> 