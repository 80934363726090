<template>
  <div>
      <b-container>
          <div style="margin-top: 20%; text-align: center; margin-bottom: 20%;">
            <h1 v-if="error.statusCode === 404 || error.statusCode === 500">{{ $t('page.page_not_found.title') }}</h1>
            <h1 v-else>{{ $t('page.page_not_found.title') }}</h1>
            <h1>{{$t('page.page_not_found.page_not_found')}}</h1>
            <p>{{ $t('page.page_not_found.description') }}</p>
            </div>
      </b-container>
  </div>
</template>

<script>

  export default {
    props: ['error'],
    // layout: '../layouts/error.vue',
    // layout: 'layoutb', // you can set a custom layout for the error page
 
  }
</script>
<style>
    
</style>