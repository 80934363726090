<template>
    <div class="dropdown cart-dropdown" v-if="customerSettingDetails.secure_status === 'A' && customers_details.profile_status === 'A' && $store.state.sessionStorage.login_successfull " >
        <button  class="dropdown-toggle">
            <i class="icon-user"></i>
        </button>

        <div class="dropdown-menu dropdown-menu-right"  key="hasCart">
            <div class="dropdown-cart-products">
                <ul >
                    <!-- <li class="product" style="border-bottom:none;">
                        <a href="#"   @click="ViewWallet()" class="product-title">{{$t('components.user_menu.coupon_wallet')}}</a>
                    </li> -->
                    <li class="product" style="border-bottom:none;" >
                        <button @click.prevent="logout()" class="product-title">{{$t('components.user_menu.sign_out')}}</button>
                    </li>
                    
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
// import { mounted } from 'vueisotope';
import { mapGetters, mapActions } from 'vuex';
import { baseUrl } from '~/repositories/repository';
import crypto from "crypto";

function decrypt(messagebase64, keyBase64, ivBase64) {
    const key = Buffer.from(keyBase64, 'base64');
    const iv = Buffer.from(ivBase64, 'base64');
    const decipher = crypto.createDecipheriv(getAlgorithm(key), key, iv);
    let decrypted = decipher.update(messagebase64, 'hex');
    decrypted += decipher.final('utf8');
    return decrypted;
}

function getAlgorithm(key) {
    // var key = Buffer.from(keyBase64, 'base64');
    console.log("Key value", key, key.length)
    switch (key.length) {
        case 16:
            return 'aes-128-cbc';
        case 32:
            return 'aes-256-cbc';
    }
    throw new Error('Invalid key length: ' + key.length);
}

export default {
    
    data: function() {
        return {
            baseUrl: baseUrl,
            catalogueId:'',
            paymentDetails:'',
            catalogueDetails:'',
            CatalogueType:'PU',
            customers_details:'',
            // customer_id:null,
            customerSettingDetails:'',
            customer_id:''
        };
    },
     mounted: function() {
        this.customer_id = this.$route.params.id
        // this.getActivePayment();
        if (this.customer_id !== undefined && this.customer_id !== null) {
          this.GetCustomer();
        }
        this.getCustomerSetting()
    },
    computed: {
        ...mapGetters('cart', ['cartList', 'priceTotal', 'qtyTotal'])
    },
    methods: {

        //    //<---------------- decrypt customerId -------------------->
        //    decryptCustomerId(customer_id) {
        //     var data = customer_id
        //     var keyBase64 = "YVc1bWIyMWxlbnB2Wm5samIzVndiMj09eiyOLtzOoSh=";
        //     var ivBase64 = 'aW5mb21lenpvZnljb3Vwb2==';
        //     console.log("data##", data);
        //     var decryptedCipherText = decrypt(data, keyBase64, ivBase64);
        //     console.log("decryptedCipherText", decryptedCipherText);
        //     this.customer_id = decryptedCipherText;
        //     console.log(' this.customer_id@@@ ', this.customer_id);
        // },

        ...mapActions('cart', ['removeFromCart']),

                            // <----------- Get Coustomer Details Api Function --------------->
        async GetCustomer() {

            console.log('UserMenu -customer_id',this.customer_id);
            var encrypt = this.$route.query.encrypted ? this.$route.query.encrypted : 'N'
            await this.$axios.$get(`/api/mzapps/customer/${this.customer_id}?country_code=${this.$route.params.country}&encrypted=${encrypt}`).then(response => {
                console.log('customer respponse', response);
                this.customers_details = response;
                // var customerGroupId = this.customers_details.customer_group_id
                console.log('customerGroupId', this.customers_details);
            }).catch(error => {
                console.log("customer Error ", error);
            })

        },
        
        getCustomerSetting: function () {
            var url = `/api/mzapps/customersetting?country_code=${this.$route.params.country}`;
            this.$axios.$get(url).then(response => {
                this.customerSettingDetails = response.customer_setting;
                console.log("customerSettingDetails", this.customerSettingDetails);
            }).catch(error => {
                console.log("customer Error ", error);
            })
        },
    
                            //<---------------- logout Function ------------------>

        logout: function () {
            this.$store.commit("sessionStorage/assignuserLoginSuccessfull", false);
        },

                            //<---------------- View Wallet Function ------------------>
                            
        // ViewWallet: function() {
        //     var wallet_url = this.customerS_details.wallet_url;
          
        //     window.open(wallet_url,"_blank")
           
        // }
    },
};
</script>
<style scoped>
.cart-dropdown .dropdown-menu.dropdown-menu-right{
    right: -22px !important;

}
.cart-dropdown .dropdown-menu{
width:210px !important;
}    

.cart-dropdown .product-title {
    font-size: 1.3rem !important;
    color: #000; 
    margin-bottom: -0.6rem !important;
    line-height: 0 !important;
    
}

.cart-dropdown .product-title:hover{
    color: black;
}

 
</style>