
export const state = () => ({
    merchantDetails: '',
    catalogueDetails:'',
    catalogue_code:''
})

export const mutations = {
    catalogueDetails: function(state, value) {
        state.catalogueDetails = value;

    },
    merchantDetails: function(state, value) {
        state.merchantDetails = value;
    },
    assignCatalogue: function(state, value) {
        state.catalogue_code = value;
    },
}