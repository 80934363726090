export const state = () => ({
    productItems: [],
    eventItems: null,
    currentDate: '',
    countryCode: '',
    catalogueItems: '',
    merchantDetails: '',
    catalogueDetails: '',
    lang: '',
    errorMsg: '',
    lang_str: '',
    adyenConfig: null,
    adyenAction: null,
    token: '',
    merchant_id: '',
    event_subTotal: 0,
    omise_id: '',
    customerDetails: null,
    merchantSettings: '',
    count: 0,
    loginStatus: false,
    customer_id: null,
    single_mode: 'N',
    single_mode_back: 'N',
    catalogue_code: '',
    payment_info: '',
    select_lang: '',
    userDetails: null,
    user_display: 'N',
    added_to_cart: [],
    category_id: null,
    catalogueid: null,
    back_flag: false,
    shareBtn:false,
    tags:'',
    priceMax:'',
    priceMin:'',
    catalogue_url:null,
    //.................... new -------------------------
    customerDetails:null,
    customer_id:'',
    params_customer_id: '',
    user_details: null,
    encrypted: '',
    merchant_details: '',
    coupon_name:'',
    coupon_serial: '',
    list_type:'A',
    login_successfull:false,
    customerSettingDetails:'',
    couponResponse:'',
    histroyCouponResponse:'',
    histroyCoupontags:'',
    coupontags:'',
    customerMembershipDetails:'',
    MultipleTransfer:false,
    SerialDetails:'',
})

export const mutations = {
    assignSerialDetails: function(state, value) {
        state.SerialDetails= value;
    },

    assignMultipleTransfer: function(state, value) {
        state.MultipleTransfer= value;
    },

    assigncustomerMembershipDetails: function(state, value) {
        state.customerMembershipDetails= value;
    },
    assignCoupontags: function(state, value) {
        state.coupontags= value;
    },
    assignhistroyCoupontags: function(state, value) {
        state.histroyCoupontags = value;
    },
    assignhistroyCouponResponse: function(state, value) {
        state.histroyCouponResponse = value;
    },
    assigncouponResponse: function(state, value) {
        state.couponResponse = value;
    },
    assigncustomerSettingDetails: function(state, value) {
        state.customerSettingDetails = value;
    },
    assignuserLoginSuccessfull: function(state, value) {
        state.login_successfull = value;
    },
    assignListType: function(state, value) {
        state.list_type = value;
    },
    assigncustomerDetails: function(state, value) {
        state.customerDetails = value;
    },
    assigncustomerID: function(state, value) {
        console.log("EE customer ID: " ,value);
        state.customer_id = value;
    },
    assignParamsCustomerID: function(state, value) {
        console.log("value", value);
        state.params_customer_id = value;
    },
    
    assignencrypted: function(state, value) {
        state.encrypted = value;
    },
    assignMerchantDetails: function(state, value) {
        state.merchant_details = value;
    },
    assignCouponName: function(state, value) {
        state.coupon_name = value;
    },
    assignCouponSerial: function(state, value) {
        state.coupon_serial = value;
    },
    


    
    assignCatalogueUrl: function(state, value) {
        state.catalogue_url = value;
    },
    assignTags: function(state, value){
        state.tags = value;
    },
    assignPriceMax: function(state, value){
        state.priceMax = value;
    },
    assignPriceMin: function(state, value){
        state.priceMin = value;
    },
    assignShare: function(state, value){
        state.shareBtn = value;
    },
    assignBack: function(state, value) {
        state.back_flag = value;
    },
    assignCatalogueId: function(state, value) {
        state.catalogueid = value;
    },
    assignCategoryId: function(state, value) {
        state.category_id = value;
    },
    AssignAddedToCart: function(state, value) {
        state.added_to_cart.push(value);
    },
    RemoveFromCart: function(state, value) {
        console.log("Remove From Cart : Cart List:",state.added_to_cart)
        console.log("Remove Cart Item : ",value)
        var idx = state.added_to_cart.findIndex((p) => p.product_id === value.product.catalogue_product_id)
                console.log("product index:", idx)
        if (idx !== -1) {
            state.added_to_cart.splice(idx,1)
            
        } 
        console.log("removed :",state.added_to_cart)
        //state.added_to_cart.push(value);
    },
    assignuserDisplay: function(state, value) {
        state.user_display = value;
    },
    assignPaymentInfo: function(state, value) {
        state.payment_info = value;
    },
    assignSelectLang: function(state, value) {
        console.log('assignSelectLang', value);
        state.select_lang = value;
    },
    assignCatalogue: function(state, value) {
        state.catalogue_code = value;
    },
    clearEvents: function(state, value) {
        state.eventItems = null;
        state.event_modifiers = [];
        state.event_selectedDate = '';
        state.event_selectedSlots = [];
        state.event_customMessage = '';
    },
    assignDate: function(state, value) {
        state.currentDate = value;
    },
    assignErrormsg: function(state, value) {

        state.errorMsg = value;
    },
    assignCount: function(state, value) {
        state.count++;
    },
    assignCustomerDetails: function(state, value) {
        state.customerDetails = value;
    },
    assignuserStatus: function(state, value) {
        state.loginStatus = value;
    },
    assignuserId: function(state, value) {
        state.customer_id = value;
    },
    
    assignSingleModeBack: function(state, value) {
        state.single_mode_back = value;
    },
    assignSingleMode: function(state, value) {
        state.single_mode = value;
    },
    assignOmiseId: function(state, value) {
        state.omise_id = value;
    },
    clearOmiseId: function(state, value) {
        state.omise_id = '';
    },
    assignEventSubTotal: function(state, value) {
        state.event_subTotal = value;
    },
    assignEventDate: function(state, value) {
        state.event_selectedDate = value
    },
    assignEventSlots: function(state, value) {
        console.log("Assign slots ", value);
        state.event_selectedSlots = value
    },
    assignCustomMessage: function(state, value) {
        state.event_customMessage = value
    },
    assignToken: function(state, value) {
        console.log("Session #### ", typeof value)
        state.token = value;
    },
    assignMerchant: function(state, value) {
        state.merchant_id = value;
    },
    assignmerchantSetting: function(state, value) {

        state.merchantSettings = value;
    },
    assignLang: function(state, value) {
        console.log("Session #### ", typeof value)
        state.lang = value;
    },
    clearLang: function(state, value) {
        state.lang = '';
    },
    assignConfig: function(state, value) {
        state.adyenConfig = value;
    },
    assignAction: function(state, value) {
        state.adyenAction = value;
    },
    assignLangStr: function(state, value) {
        state.lang_str = value;
    },
    assignProductItem: function(state, value) {

        var find = state.productItems.findIndex(p => p.catalogue_product_id === value.catalogue_product_id);
        if (find === -1) {
            value['selectedQuantity'] = 1;
            state.productItems.push(value);
        } else {

            if (state.productItems[find].selectedQuantity < value.available_coupon) {
                console.log('assignProductItem value', value);
                state.productItems[find]['selectedQuantity'] = Number(state.productItems[find].selectedQuantity) + 1;
            }

        }
        console.log("productItems ", state.productItems);
    },
    assignCatalogueItem: function(state, value) {
        console.log("assignCatalogueItem ", value);
        state.catalogueItems = value;

    },
    assignMerchantItem: function(state, value) {
        console.log("assignMerchantItem ", value);
        state.merchantDetails = value;

    },
    assignCountry: function(state, value) {

        state.countryCode = value;
    },
    catalogueDetails: function(state, value) {
        console.log("catalogueDetailstore", value);
        state.catalogueDetails = value;
    },
    assignEvent: function(state, value) {
        console.log("Assign Event ", state.eventItems);
        if (state.eventItems.campaign_limit > value.selectedQuantity) {
            state.eventItems.selectedQuantity = Number(value.selectedQuantity) + 1;
        }
    },
    removeEvent: function(state, value) {
        if (state.eventItems.selectedQuantity > 1) {
            state.eventItems.selectedQuantity = Number(value.selectedQuantity) - 1;
        }
    },
    assign: function(state, value) {
        // console.log("coupon add session code ", value);
        // state.productItems = [];
        var find = state.productItems.findIndex(p => p.coupon_id === value.coupon_id);
        if (find === -1) {
            value['selectedQuantity'] = 1;
            state.productItems.push(value);
        } else {
            if (state.productItems[find].selectedQuantity < value.available_packs)
                state.productItems[find].selectedQuantity = Number(state.productItems[find].selectedQuantity) + 1;
        }
    },
    clear: function(state, value) {
        state.productItems = [];
        state.customerDetails = null;
        // state.productItems.push(value);
    },
    remove: function(state, value) {
        var find = state.productItems.findIndex(c => c.catalogue_product_id === value.catalogue_product_id);
        if (find != -1 && Number(state.productItems[find].selectedQuantity) > 0) {
            state.productItems[find].selectedQuantity = Number(state.productItems[find].selectedQuantity) - 1;
            console.log(find, state.productItems[find].selectedQuantity)
            if (Number(state.productItems[find].selectedQuantity) === 0) {
                state.productItems[find].selectedQuantity = 0;
                state.productItems.splice(find, 1);
            }
        } else {
            console.log("Else ", find)
        }

    },
    assignEventModifier: function(state, value) {
        state.event_modifiers = value;
    },
    removeEventModifierCount: function(state, value) {
        var find = state.event_modifiers.findIndex(c => c.campaign_modifier.campaign_modifier.campaign_modifier_id === value.campaign_modifier.campaign_modifier.campaign_modifier_id);
        if (find != -1 && Number(state.event_modifiers[find].selectedCount) > value.campaign_modifier.campaign_modifier.min_qty) {
            if (state.event_modifiers[find].selectedCount >= value.campaign_modifier.campaign_modifier.min_qty) {
                state.event_modifiers[find].selectedCount = Number(state.event_modifiers[find].selectedCount) - 1;
            }
            // console.log(find, state.event_modifiers[find].selectedCount)
            if (Number(state.event_modifiers[find].selectedCount) === 0) {
                state.event_modifiers[find].selectedCount = 0;
                // state.event_modifiers.splice(find, 1);
            }
        } else {
            console.log("Else ", find)
        }

    },
    assignEventModifierCount: function(state, value) {
        console.log("assignEventModifierCount ", value);
        var find = state.event_modifiers.findIndex(c => c.campaign_modifier.campaign_modifier.campaign_modifier_id === value.campaign_modifier.campaign_modifier.campaign_modifier_id);
        if (find === -1) {
            value['selectedCount'] = 2;
            state.event_modifiers.push(value);
        } else {

            if (state.event_modifiers[find].selectedCount < value.campaign_modifier.campaign_modifier.max_qty)
                state.event_modifiers[find].selectedCount = Number(value.selectedCount) + 1;
        }
    },
    addrange: function(state, value) {
        state.productItems[value.index].quantity = value.value;
    }
}